@keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-10rem);
    }
  
    80% {
      transform: translateX(1rem);
    }
  
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
  
    80% {
      transform: translateX(-1rem);
    }
  
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  
  @keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(3rem);
    }
  
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes moveInTop {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }
  
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes grow {
    0% {
      opacity: 0;
      transform: scale(.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
    }
    70% { transform: scale(.9); }
    100% { transform: scale(1); }
  }


  @-webkit-keyframes gradientBG {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:92% 0%}
}
@-moz-keyframes gradientBG  {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:92% 0%}
}
@keyframes gradientBG {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:92% 0%}
  }
