@import "../../main.scss";
@import "../../sass/animations.scss";

.project {
    position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    filter: sepia(50%);
    background-color: rgba($color-white, .1);
   
    
  }

  &__title{
    position: absolute;
    top: 0;
    left: 40%;
    margin: 1rem auto;
    z-index: 10;

    text-transform: uppercase;
    color:$color-white;
    text-shadow: $heading-shadow;
  
    
    font-family: 'Alegreya SC', serif;
    font-size: 5rem;
      font-weight: 700;
      letter-spacing: 1.2rem;
      text-align: center;

      @media only screen and (max-width: $bp-large) {
        left: 35%;
        }
      @media only screen and (max-width: $bp-medium) {
        left: 33%;
        }
      @media only screen and (max-width: $bp-small) {
        left: 25%;
        top:8%;
        margin-bottom: 6rem;
        font-size: 3.5rem;
        font-weight: 500;
        }
  }

  // this is the wrapper for the cards
  &__cardBox{
    position: absolute;
    margin: 2rem auto;
    padding: 4rem 10rem;

    min-width: 100%;
    height: 90%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
   
    
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 4rem;
 
  justify-content: center;
  align-items: center;

  
  @media only screen and (max-width: $bp-largest) {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    // padding: 4rem 10rem;
    height: 80%;
    top: 43%;
    margin: 4rem auto;
  
}
  @media only screen and (max-width: $bp-large) {
    
    height: 70%;
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: 8rem;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
}
  @media only screen and (max-width: $bp-small) {
    height: 65%;
    top: 47%;
    grid-template-columns: repeat(1, max-content);
    grid-row-gap: 8rem;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
}
  
  }

  &__buttonBox{
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: $bp-small){
      bottom: -5%;
      left: 45%;
    }
  }

  & Video {
    width: 100%;
    height: 100%;
    object-fit: cover ;
  }
}