@import "../../main.scss";
@import "../../sass/animations.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      to bottom left,
      rgba($color-overlay-1, 0.9),
      rgba($color-overlay-1, 0.6),
      rgba($color-overlay-2, 0.2),
      rgba($color-overlay-1, 0.4),
      rgba($color-overlay-3, 0.5)
    );
  }

  &__textBox {
    position: absolute;
    width: 90%;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;

    @media only screen and (max-width: $bp-small) {
      top: 50%
  }
    
  }

  &__buttonBox{
    position: absolute;
    z-index: 10;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: $bp-medium) {
     bottom: 0%;
  }


 
  }

  & Video {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


// text, logo
.heading__primary {
  text-align: center;
  
 
  img {
    width: 25rem;
    height: 25rem;
    display: block;
    margin: 5rem auto;
    animation: moveInTop 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;

    @media only screen and (max-width: $bp-large) {
      margin-top: 8rem;
      width: 23rem;
      height: 23rem;
  }

  @media only screen and (max-width: $bp-small) {
    display: none;
}
  }

}

.main__heading{
  text-transform: uppercase;
  color:$color-white;
  text-shadow: $heading-shadow;
  backface-visibility: hidden;
  margin-bottom: 6rem;
  font-family: 'Alegreya SC', serif;

  &__first{
    display: block;
    font-size: 6rem;
    font-weight: 900;
    letter-spacing: 2.5rem;
    margin-bottom: 5rem;

    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }

  &__second {
    // display: block;
    font-size: 2.1rem;
    font-weight: 700;
    letter-spacing: 1.2rem;
    animation: moveInRight 2s ease-out;
 

    display: grid;
    grid-template-columns:  2fr 1fr 1fr 1fr;
    grid-column-gap: 6rem;
    justify-items: center;
    align-items: center;

    @media only screen and (max-width: $bp-largest) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 4rem;
  }
    @media only screen and (max-width: $bp-small) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 4rem;
      letter-spacing: 1rem;
      font-weight: 500;
      font-size: 1.8rem;
  }

  }


}