@import "../../main.scss";
@import "../../sass/animations.scss";

.contain {
    width: 100%;
    padding: 1rem;
    transform: skewX(12deg);

    @media only screen and (max-width: $bp-large) {
      transform: skewX(8deg);
    }

    @media only screen and (max-width: $bp-small) {
      transform: skewX(0deg);
      padding: 2rem;
    }
  }

.wrapper {
    display: grid;
    grid-template-columns: max-content min-content;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(100px, auto);
    }
    
}
  input:focus,
  textarea:focus {
    outline: 3px solid $primary-color-light;
  }
  
  input,
  textarea,
  button {
    width: 100%;
    justify-content: center;
    
  }

  .btn {
    text-align: center;
  }
  

  ul {
    list-style: none;
  }
  
  .contacts {
    color: $color-black;
    font-size: $default-font-size;
    padding-left: 6rem;
  align-self: start;
  text-align: left;
  margin: 6rem auto;

  @media only screen and (max-width: $bp-medium) {
    margin: 2rem;
    padding-left: 0;
    text-align: center;
     }

     @media only screen and (max-width: $bp-small) {
      // transform: skewX(0deg);
    }
  }

 
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
   justify-content: space-around;

   @media only screen and (max-width: $bp-medium) {
    margin-bottom: 2rem;
    text-align: center;
    justify-content: center;
     }
   @media only screen and (max-width: $bp-small) {
    // transform: skewX(0deg);
    
     }
  }
  form label {
    display: block;
  }

  
  .full-width {
    grid-column: 1 / 3;
  }
  

  input,
  textarea {
    padding: 1em;
  }
  

  button:hover, button:focus {
    background: $primary-color-light;
    outline: 0;
    grid-column: 1 / 2;
 
  }