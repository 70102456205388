
// font size
$default-font-size: 1.6rem;

// Responsive Breakpoints
$bp-largest: 75em;   // 1200px
$bp-large: 62.5em;   // 1000px
$bp-medium: 50em;    // 800px;
$bp-small: 37.5em;    // 600px;

// Main colors
$primary-color: #0e6ba8;
$primary-color-light: #a6e1fa;
$primary-color-dark-1: #001c55;
$primary-color-dark-2: #010b44;

// standard colors
$color-white: #f5f5f5;
$color-black: #1b1b1b;

$color-grey-light-1: #faecec;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;


// over lay colors
$color-overlay-1: #4100ff;
$color-overlay-2: #0059ff;
$color-overlay-3: #fc19e9;

// shadow effects
$heading-shadow: 1px 1px 1px $primary-color-dark-1,
  1px 2px 1px $primary-color-dark-1, 1px 3px 1px $primary-color-dark-1,
  1px 4px 1px $primary-color-dark-1, 1px 5px 1px $primary-color-dark-1,
  1px 6px 1px $primary-color-dark-1, 1px 7px 1px $primary-color-dark-1,
  1px 8px 1px $primary-color-dark-1, 1px 9px 1px $primary-color-dark-1,
  1px 10px 1px $primary-color-dark-1,
  1px 18px 6px rgba($primary-color-dark-2, 0.4),
  1px 22px 10px rgba($primary-color-dark-2, 0.2),
  1px 25px 35px rgba($primary-color-dark-2, 0.2),
  1px 30px 60px rgba($primary-color-dark-2, 0.4);

// gradient effects


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  // this defines what 1 rem is
  font-size: 62.5%; //1rem = 10px 10px/16px = 62.5%
  @media only screen and (max-width: $bp-largest) {
    font-size: 60%;
}

@media only screen and (max-width: $bp-medium) {
  font-size: 50%;
}
  @media only screen and (max-width: $bp-small) {
    font-size: 45%;
}

}

body {
  box-sizing: border-box;
  padding: 3rem;
  line-height: 1.7;
  
  
  // font-family: 'KoHo', sans-serif;
  font-family: 'Bellota', cursive;
  font-weight: 400;
}

